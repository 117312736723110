import * as Sentry from '@sentry/nuxt';
import { useRuntimeConfig } from '#imports';

const { public: { isVitest, sentry } } = useRuntimeConfig();

if (isVitest) {
  console.log('Sentry [CSR]: Not loaded, in Vitest!');
}
else if (!sentry?.dsn) {
  console.log('Sentry [CSR]: Not loaded, DSN not set');
}
else {
  const {
    allowUrls,
    dsn,
    environment,
    ignoreErrors,
    profilesSampleRate,
    tracesSampleRate,
  } = sentry;

  Sentry.init({
    dsn,
    environment,
    ignoreErrors,
    profilesSampleRate,
    tracePropagationTargets: allowUrls,
    tracesSampleRate,
  });

  console.log('Sentry [CSR]: Loaded successfully');
}
